import React from 'react';
import {
  defer,
  type LinksFunction,
  type MetaFunction,
  type LoaderArgs,
  type AppLoadContext,
  redirect,
  json,
} from '@shopify/remix-oxygen';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useCatch,
  useLoaderData,
  useMatches,
  useLocation,
} from '@remix-run/react';
import {
  ShopifySalesChannel,
  Seo,
  type SeoHandleFunction,
} from '@shopify/hydrogen';
import {ShopifyProvider} from '@shopify/hydrogen-react';

import {Layout} from '~/components';
import {GenericError} from './components/GenericError';
import {NotFound} from './components/NotFound';
import styles from './styles/app.css';
import favicon from '../public/favicon.svg';

import {parseMenu, type EnhancedMenu, getPathPrefix} from './lib/utils';
import invariant from 'tiny-invariant';
import {
  Shop,
  Cart,
  Collection,
  CountryCode,
  LanguageCode,
} from '@shopify/hydrogen/storefront-api-types';
import {useAnalytics} from './hooks/useAnalytics';
import type {StorefrontContext} from './lib/type';
import {useEffect, useRef} from 'react';
import {ArtistaBoardProvider} from './components/tooa/artista-board.context';
import {ScreenDimensionsProvider} from './components/tooa/contexts/screen-dimensions.context';
import {MenuContextProvider} from './components/tooa/contexts/menu.context';
// import cssUrl from 'public/test/app.css';
import {localizationCookie} from '~/cookies';
import {LoaderProvider} from './components/tooa/contexts/loader.context';

let isMount = true;
// const seo: SeoHandleFunction<typeof loader> = ({data, pathname}) => ({
//   title: data?.layout?.shop?.name,
//   titleTemplate: '%s | Bitforce.shop',
//   description: data?.layout?.shop?.description,
//   handle: '@shopify',
//   url: `https://bitforce.shop${pathname}`,
// });
// export const handle = {seo};

export const links: LinksFunction = () => {
  return [
    {rel: 'preconnect', href: 'https://cdn.shopify.com'},
    {rel: 'preconnect', href: 'https://fonts.googleapis.com'},
    {rel: 'preconnect', href: 'https://fonts.gstatic.com'},
    {rel: 'stylesheet', href: styles},
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap',
    },
    {rel: 'icon', type: 'image/svg+xml', href: favicon},
  ];
};
export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  viewport: 'width=device-width,initial-scale=1, user-scalable=no',
  robots: 'noindex',
});

export async function loader({request, context}: LoaderArgs) {
  // const test = await fetch(cssUrl);
  // const inlineCss = await test.text();
  // console.log();

  const {pathname} = new URL(request.url);
  if (pathname.includes('/tooa')) {
    const cookies = request.headers.get('Cookie');
    console.log('cookies', cookies);

    try {
      const localizationCookie = cookies
        ?.split(',')[0]
        ?.split(';')
        .find((cookie) => cookie.includes('localization'))
        ?.split('=')[1];

      const localizationPrefix = pathname.split('/')[1];

      if (
        localizationCookie &&
        localizationPrefix &&
        localizationCookie !== localizationPrefix
      ) {
        const headers = new Headers();
        console.log('redirecting', `/${localizationCookie}/tooa`);

        headers.set('Location', `/${localizationCookie}/tooa`);
        return json({}, {status: 303, headers});
      }
    } catch (e) {
      console.error('error decoding the cookie', error);
    }
  }

  if (context.storefront.i18n.notFound) {
    const headers = new Headers();
    const localization =
      `${context.env.PUBLIC_DEFAULT_LANGUAGE_CODE}-${context.env.PUBLIC_DEFAULT_COUNTRY_CODE}`.toLowerCase();
    headers.set('Location', `/${localization}/language-not-found`);
    const status = 303;
    return json({}, {status, headers});
  }

  const [cartId, customerAccessToken, layout] = await Promise.all([
    context.session.get('cartId'),
    context.session.get('customerAccessToken'),
    getLayoutData(context),
  ]);

  return defer({
    dictionary: context.dictionary,
    layout,
    customerAccessToken,
    i18n: context.storefront.i18n,
    cart: cartId ? getCart(context, cartId) : undefined,
    analytics: {
      shopifySalesChannel: ShopifySalesChannel.hydrogen,
      shopId: layout.shop.id,
    },
    // inlineCss,
    env: {
      PUBLIC_DEFAULT_COUNTRY_CODE: context.env.PUBLIC_DEFAULT_COUNTRY_CODE,
      PUBLIC_DEFAULT_LANGUAGE_CODE: context.env.PUBLIC_DEFAULT_LANGUAGE_CODE,
      PUBLIC_STORE_DOMAIN: context.env.PUBLIC_STORE_DOMAIN,
      PUBLIC_STOREFRONT_API_TOKEN: context.env.PUBLIC_STOREFRONT_API_TOKEN,
      PUBLIC_STOREFRONT_API_VERSION: context.env.PUBLIC_STOREFRONT_API_VERSION,
      PUBLIC_ECOMMERCE_DOMAIN: context.env.PUBLIC_ECOMMERCE_DOMAIN,
      ARTISTA_SHOW_DEBUG: context.env.ARTISTA_SHOW_DEBUG,
      PUBLIC_ENVIRONMENT: context.env.PUBLIC_ENVIRONMENT,
    },
  });
}
export default function App() {
  const data = useLoaderData<typeof loader>();

  // FIXME
  const hasUserConsent = true;
  useAnalytics(hasUserConsent, data.i18n);
  const location = useLocation();
  const matches = useMatches();

  // const googleFontRef = useRef<any>();
  // useEffect(() => {
  //   if (googleFontRef.current) {
  //     setTimeout(() => {
  //       googleFontRef.current.media = 'all';
  //     }, 3000);
  //   }
  // }, [googleFontRef]);

  // React.useEffect(() => {
  //   if (location.pathname === '/tooa') {
  //     const mounted = isMount;
  //     isMount = false;
  //     if ('serviceWorker' in navigator) {
  //       if (navigator.serviceWorker.controller) {
  //         navigator.serviceWorker.controller?.postMessage({
  //           type: 'REMIX_NAVIGATION',
  //           isMount: mounted,
  //           location,
  //           matches,
  //           manifest: window.__remixManifest,
  //         });
  //       } else {
  //         const listener = async () => {
  //           await navigator.serviceWorker.ready;
  //           navigator.serviceWorker.controller?.postMessage({
  //             type: 'REMIX_NAVIGATION',
  //             isMount: mounted,
  //             location,
  //             matches,
  //             manifest: window.__remixManifest,
  //           });
  //         };
  //         navigator.serviceWorker.addEventListener(
  //           'controllerchange',
  //           listener,
  //         );
  //         return () => {
  //           navigator.serviceWorker.removeEventListener(
  //             'controllerchange',
  //             listener,
  //           );
  //         };
  //       }
  //     }
  //   }
  // }, [location]);
  useEffect(() => {
    if (location.pathname.includes('/tooa')) {
      const root = document.documentElement;
      const nightGradient = 'linear-gradient(225deg, #B1EAD1 0%, #A6AFE1 100%)';
      const dayGradient = 'linear-gradient(225deg, #FFB4B4 0%, #FFED99 100%)';
      const currentTime = new Date().getHours();

      const gradient =
        currentTime <= 17 && currentTime > 5 ? dayGradient : nightGradient;

      root.style.setProperty('--tooa-gradient', gradient);
    }
  }, []);

  return (
    <html lang={data.i18n?.languageCode}>
      <head>
        <Seo />
        <Meta />
        <Links />
        {/* <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Jost:wght@400;700&display=swap"
          media="print"
          ref={googleFontRef}
        /> */}
        {/* <style>{data.inlineCss}</style> */}
      </head>
      <body>
        <ShopifyProvider
          storeDomain={`https://${data.env.PUBLIC_STORE_DOMAIN}`}
          storefrontToken={data.env.PUBLIC_STOREFRONT_API_TOKEN}
          storefrontApiVersion={data.env.PUBLIC_STOREFRONT_API_VERSION}
          countryIsoCode={
            data.i18n.countryCode || data.env.PUBLIC_DEFAULT_COUNTRY_CODE
          }
          languageIsoCode={
            data.i18n.languageCode || data.env.PUBLIC_DEFAULT_LANGUAGE_CODE
          }
        >
          {location.pathname.includes('/tooa') ? (
            <ScreenDimensionsProvider>
              <MenuContextProvider>
                <LoaderProvider>
                  <ArtistaBoardProvider>
                    <Outlet></Outlet>
                  </ArtistaBoardProvider>
                </LoaderProvider>
              </MenuContextProvider>
            </ScreenDimensionsProvider>
          ) : (
            <>
              <Layout
                layout={data.layout as LayoutData}
                key={`${data.i18n.languageCode}-${data.i18n.countryCode}`}
              >
                <Outlet />
              </Layout>
              <ScrollRestoration />
            </>
          )}
        </ShopifyProvider>

        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify(data.env)}`,
          }}
        />
        <Scripts />
      </body>
    </html>
  );
}
export function CatchBoundary() {
  const [root] = useMatches();
  const caught = useCatch();
  const isNotFound = caught.status === 404;
  return (
    <html lang={root?.data?.i18n?.languageCode}>
      <head>
        <title>{isNotFound ? 'Not found' : 'Error'}</title> <Meta /> <Links />
      </head>
      <body>
        <Layout
          layout={root?.data?.layout}
          key={`${root?.data?.i18n?.languageCode}-${root?.data?.i18n?.countryCode}`}
        >
          {isNotFound ? (
            <NotFound type={caught.data?.pageType} />
          ) : (
            <GenericError
              error={{message: `${caught.status} ${caught.data}`}}
            />
          )}
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}
export function ErrorBoundary({error}: {error: Error}) {
  const [root] = useMatches();
  return (
    <html lang={root?.data?.i18n?.languageCode}>
      <head>
        <title>Error</title> <Meta /> <Links />
      </head>
      <body>
        <Layout layout={root?.data?.layout}>
          <GenericError error={error} />
        </Layout>
        <Scripts />
      </body>
    </html>
  );
}

const LAYOUT_QUERY = `#graphql
  query layoutMenus(
    $language: LanguageCode
    $headerMenuHandle: String!
    $footerMenuHandle: String!
  ) @inContext(language: $language) {
    shop {
      id
      name
      description
    }
    headerMenu: menu(handle: $headerMenuHandle) {
      id
      items {
        ...MenuItem
        items {
          ...MenuItem
          items {
            ...MenuItem
          }
        }
      }
    }
    footerMenu: menu(handle: $footerMenuHandle) {
      id
      items {
        ...MenuItem
        items {
          ...MenuItem
        }
      }
    }
  }
  fragment MenuItem on MenuItem {
    id
    resourceId
    tags
    title
    type
    url
  }
`;
export interface LayoutData {
  headerMenu: EnhancedMenu;
  footerMenu: EnhancedMenu;
  shop: Shop;
  cart?: Promise<Cart>;
  collections: Collection[];
}
async function getLayoutData({storefront}: AppLoadContext) {
  const HEADER_MENU_HANDLE = 'kocca-header-menu';
  const FOOTER_MENU_HANDLE = 'kocca-footer-menu';
  const [data, collectionsData] = await Promise.all([
    storefront.query<LayoutData>(LAYOUT_QUERY, {
      variables: {
        headerMenuHandle: HEADER_MENU_HANDLE,
        footerMenuHandle: FOOTER_MENU_HANDLE,
        language: storefront.i18n.languageCode,
      },
    }),
    storefront.query<LayoutData>(COLLECTIONS_IMAGE_QUERY, {variables: {}}),
  ]);
  invariant(data, 'No data returned from Shopify API');
  /* Modify specific links/routes (optional) @see: https://shopify.dev/api/storefront/unstable/enums/MenuItemType e.g here we map: - /blogs/news -> /blog/news - /blog/news/blog-post -> /blog/news/blog-post - /collections/all -> /products */ const customPrefixes =
    {BLOG: 'blog', CATALOG: 'products'};
  const headerMenu = data?.headerMenu
    ? parseMenu(data.headerMenu, customPrefixes)
    : undefined;
  const footerMenu = data?.footerMenu
    ? parseMenu(data.footerMenu, customPrefixes)
    : undefined;
  return {
    shop: data.shop,
    headerMenu,
    footerMenu,
    collections: collectionsData.collections.nodes,
  };
}

const COLLECTIONS_IMAGE_QUERY = `#graphql
  query collections {
    collections(first: 100) {
      nodes {
        id
        title
        metafields(
          identifiers: [
            {namespace: "bitforce", key: "is_highlight"}
            {namespace: "collection", key: "menu_image"}
          ]
        ) {
          key
          value
          reference {
            ... on MediaImage {
              image {
                id
                url
                width
                height
                altText
              }
            }
          }
        }
      }
    }
  }
`;

const CART_QUERY = `#graphql
  query CartQuery($cartId: ID!, $country: CountryCode, $language: LanguageCode)
    @inContext(country: $country, language: $language) {
    cart(id: $cartId) {
      ...CartFragment
    }
  }

  fragment CartFragment on Cart {
    id
    checkoutUrl
    totalQuantity
    buyerIdentity {
      countryCode
      customer {
        id
        email
        firstName
        lastName
        displayName
      }
      email
      phone
    }
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          attributes {
            key
            value
          }
          cost {
            totalAmount {
              amount
              currencyCode
            }
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
          }
          merchandise {
            ... on ProductVariant {
              id
              availableForSale
              compareAtPrice {
                ...MoneyFragment
              }
              price {
                ...MoneyFragment
              }
              requiresShipping
              title
              image {
                ...ImageFragment
              }
              product {
                handle
                title
                id
              }
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }
    cost {
      subtotalAmount {
        ...MoneyFragment
      }
      totalAmount {
        ...MoneyFragment
      }
      totalDutyAmount {
        ...MoneyFragment
      }
      totalTaxAmount {
        ...MoneyFragment
      }
    }
    note
    attributes {
      key
      value
    }
    discountCodes {
      code
    }
  }

  fragment MoneyFragment on MoneyV2 {
    currencyCode
    amount
  }

  fragment ImageFragment on Image {
    id
    url
    altText
    width
    height
  }
`;
export async function getCart({storefront}: StorefrontContext, cartId: string) {
  invariant(storefront, 'missing storefront client in cart query');
  const {cart} = await storefront.query<{cart?: Cart}>(CART_QUERY, {
    variables: {
      cartId,
      country: storefront.i18n.countryCode,
      language: storefront.i18n.languageCode,
    },
    cache: storefront.CacheNone(),
  });
  // console.log('cart', cart);

  return cart;
}
